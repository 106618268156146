import {
  CARD_PRODUCT_FRAME_LAYOUTS,
  ICardProductFrameContentItem,
  ICardProductFrameImageContent,
  ICardProductFrameItem,
  ICardProductFrameLayout,
  IImageAssetContent,
} from '@eulogise/core'
import { UtilHelper } from './UtilHelper'
import { ImageHelper } from './ImageHelper'
import { STYLE } from '@eulogise/client-core'
import { CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD } from './cardProduct.constants'

const ID_LENGTH = 8

export class CardProductFrameHelper {
  public static calculateNewContentSizeOnAddingFrame({
    oldLayout,
    newLayout,
    pageContentWidth,
  }: {
    oldLayout?: ICardProductFrameLayout
    newLayout: ICardProductFrameLayout
    pageContentWidth: number
  }): {
    newContentWidth: number
    newContentHeight: number
  } {
    let newContentWidth = oldLayout?.width!
    let newContentHeight = oldLayout?.height!
    if (newLayout.isAddAsRatio) {
      const newLayoutSizeRatio = newLayout.width! / newLayout.height!
      const maxHeight = oldLayout?.height ?? newLayout.height
      newContentHeight = maxHeight!
      newContentWidth = newLayoutSizeRatio * newContentHeight
      if (newContentWidth > pageContentWidth) {
        const maxWidth = pageContentWidth
        const newLayoutRatioBaseHeight = newLayout.height! / newLayout.width!
        newContentWidth = maxWidth
        newContentHeight = newLayoutRatioBaseHeight * newContentWidth
      }
    } else {
      if (newLayout.lockAspectRatio) {
        // need to compare newContentHeight against available content width
        if (newContentHeight >= pageContentWidth) {
          newContentHeight = newContentWidth
        } else {
          newContentWidth = newContentHeight
        }
      }
    }
    return {
      newContentHeight:
        newContentHeight + CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD * 2,
      newContentWidth:
        newContentWidth + CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD * 2,
    }
  }

  public static async calculateDefaultFrameImageSizeAndPosition({
    imageUrl,
    containerSize,
    isBorderShowed = false,
  }: {
    imageUrl: string
    containerSize: {
      width: number
      height: number
    }
    isBorderShowed?: boolean
  }): Promise<{
    renderWidth: number
    renderHeight: number
    transformX: number
    transformY: number
  }> {
    const { width: imageWidth, height: imageHeight } =
      await ImageHelper.getImageSizeByUrl(imageUrl)
    const imageRatio = imageWidth / imageHeight
    const clientWidth = containerSize.width
    const clientHeight = containerSize.height
    const frameWidth =
      clientWidth +
      (isBorderShowed ? (STYLE.CARD_PRODUCT_BORDER_SIZE as number) * 2 : 0)
    const frameHeight =
      clientHeight +
      (isBorderShowed ? (STYLE.CARD_PRODUCT_BORDER_SIZE as number) * 2 : 0)
    const frameRatio = frameWidth / frameHeight
    let renderWidth: number = frameWidth
    let renderHeight: number = frameHeight

    if (imageRatio > frameRatio) {
      const renderRatio = frameHeight / imageHeight
      renderWidth = imageWidth * renderRatio
      renderHeight = imageHeight * renderRatio
    } else if (imageRatio <= frameRatio) {
      const renderRatio = frameWidth / imageWidth
      renderWidth = imageWidth * renderRatio
      renderHeight = imageHeight * renderRatio
    }
    const transformX = -renderWidth / 2
    const transformY = -renderHeight / 2

    return {
      renderWidth: renderWidth + CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD,
      renderHeight: renderHeight + CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD,
      transformX: transformX - CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD,
      transformY: transformY - CARD_PRODUCT_RESIZE_AND_DRAGGING_THRESHOLD,
    }
  }

  public static getFirstContentId(
    frameItem: ICardProductFrameItem,
  ): string | undefined {
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      for (const item of frameItem.items) {
        const foundContentId = this.getFirstContentId(item)
        if (foundContentId) {
          return foundContentId
        }
      }
    } else if (frameItem.type === 'content') {
      return frameItem.id
    }
    return
  }

  public static getFirstContentFileHandle(
    frameItem: ICardProductFrameItem,
  ): string | undefined {
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      for (const item of frameItem.items) {
        const foundFileHandle = this.getFirstContentFileHandle(item)
        if (foundFileHandle) {
          return foundFileHandle
        }
      }
    } else if (frameItem.type === 'content') {
      return (
        (frameItem as ICardProductFrameContentItem)
          .content as ICardProductFrameImageContent
      )?.filestackHandle
    }
    return
  }

  public static getFirstImageContent(
    frameItem: ICardProductFrameItem,
  ): ICardProductFrameContentItem | undefined {
    console.log('frameItem', frameItem)
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      for (const item of frameItem.items) {
        const foundImageContentItem = this.getFirstImageContent(item)
        if (foundImageContentItem) {
          return foundImageContentItem
        }
      }
    } else if (
      // frameItem.type === 'content' &&
      // frameItem.content?.type === 'image'
      frameItem.type === 'content'
    ) {
      return frameItem as ICardProductFrameContentItem
    }
    return
  }

  public static getFrameContentById(
    frameItem: ICardProductFrameItem,
    contentItemId: string,
  ): ICardProductFrameContentItem | undefined {
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      for (const item of frameItem.items) {
        const foundContent = this.getFrameContentById(item, contentItemId)
        if (foundContent) {
          return foundContent
        }
      }
    } else if (frameItem.type === 'content') {
      if (frameItem.id === contentItemId) {
        return frameItem
      }
    }
    return
  }

  // TODO:FRAME we need this function when we convert all theme images to frame
  public static createFrameTemplateData({
    width,
    height,
    filePath,
  }: {
    width: number
    height: number
    filePath: string
  }) {
    return {
      width,
      height,
      content: {
        type: 'rows',
        items: [
          {
            borderRadius: '200px',
            width: '100%',
            height: '100%',
            id: 'lsx8vp0i',
            type: 'content',
            content: {
              type: 'image',
              filepath: filePath,
            },
          },
        ],
        id: 'w58ut2pd',
      },
    }
  }

  public static applyLayoutAssetsToNewLayout(
    oldLayout: ICardProductFrameLayout,
    newLayout: ICardProductFrameLayout,
  ) {
    const imageAssets = this.getFrameLayoutImageAssets(oldLayout)
    return this.assignImageAssetsToLayout(newLayout, imageAssets)
  }

  public static assignImageAssetsToLayout(
    layout: ICardProductFrameLayout | ICardProductFrameItem,
    imageAssets: Array<ICardProductFrameImageContent>,
  ): ICardProductFrameLayout | ICardProductFrameItem {
    let newLayout: ICardProductFrameLayout | ICardProductFrameItem = layout
    if (layout.type === 'columns' || layout.type === 'rows') {
      const items: Array<ICardProductFrameItem> = layout.items.map(
        (item: ICardProductFrameItem) => {
          // @ts-ignore
          if (item.type === 'content' && item.content?.type === undefined) {
            const imageAsset = imageAssets.shift()
            return {
              ...item,
              content: imageAsset,
            }
          }
          return this.assignImageAssetsToLayout(
            item,
            imageAssets,
          ) as ICardProductFrameItem
        },
      )
      return {
        ...newLayout,
        items,
      } as ICardProductFrameLayout
    }
    return newLayout
  }

  public static getFrameLayoutImageAssets(
    layout: ICardProductFrameLayout,
  ): Array<ICardProductFrameImageContent> {
    const imageAssets: Array<ICardProductFrameImageContent> = []
    if (layout.type === 'columns' || layout.type === 'rows') {
      layout.items.forEach((l: ICardProductFrameItem) => {
        if (l.type === 'content' && l.content?.type === 'image') {
          const frameContent: ICardProductFrameImageContent =
            l.content as ICardProductFrameImageContent
          imageAssets.push({
            filename: frameContent.filename,
            type: 'image',
            filepath: frameContent.filepath,
            filestackHandle: frameContent.filestackHandle,
          } as ICardProductFrameImageContent)
        } else if (l.type === 'columns' || l.type === 'rows') {
          imageAssets.push(...this.getFrameLayoutImageAssets(l))
        }
      })
    }
    return imageAssets
  }

  public static getFrameLayouts(
    noOfPhotos: null | number,
  ): Array<ICardProductFrameLayout> {
    if (!noOfPhotos) {
      return CARD_PRODUCT_FRAME_LAYOUTS
    }
    return CARD_PRODUCT_FRAME_LAYOUTS.filter((l) => {
      const photos = this.getNoOfPhotosInFrameLayout(l)
      return photos === noOfPhotos
    })
  }

  public static getNoOfPhotosInFrameLayout(
    layout: ICardProductFrameLayout,
  ): number {
    let counter = 0
    if (layout.type === 'columns' || layout.type === 'rows') {
      let count: number = 0
      layout.items.forEach((l) => {
        if (l.type === 'content') {
          count++
        } else {
          count = count + this.getNoOfPhotosInFrameLayout(l)
        }
      })
      return count
    }
    return counter
  }

  public static getUpdatedLayoutFromNewContentItem(
    layout: ICardProductFrameLayout,
    newContentItem: ICardProductFrameContentItem,
  ): ICardProductFrameLayout {
    if (layout.type === 'content') {
      return layout
    }
    return {
      ...layout,
      items: layout.items.map((ci: ICardProductFrameItem) => {
        if (ci.type === 'rows' || ci.type === 'columns') {
          return this.getUpdatedLayoutFromNewContentItem(ci, newContentItem)
        }
        if (ci.type === 'content' && ci.id === newContentItem.id) {
          return newContentItem
        }
        return ci
      }),
    } as ICardProductFrameLayout
  }

  public static generateIdForLayout(
    layout: ICardProductFrameLayout | ICardProductFrameItem,
  ): ICardProductFrameLayout {
    const newLayout = layout as any
    if (newLayout.items) {
      return {
        ...newLayout,
        items: newLayout.items.map((item: ICardProductFrameItem) =>
          this.generateIdForLayout(item),
        ),
        id: UtilHelper.generateID(ID_LENGTH),
      }
    }
    return {
      ...newLayout,
      id: UtilHelper.generateID(ID_LENGTH),
    }
  }

  public static getFirstContentItem(
    content: ICardProductFrameItem,
  ): ICardProductFrameContentItem | null {
    if (content.type === 'content') {
      return content
    }
    if (content.items.length === 0) {
      return null
    }

    return this.getFirstContentItem(content.items[0])
  }

  public static updateFrameItemPrimaryImage({
    frameItem,
    primaryImage,
  }: {
    frameItem: ICardProductFrameItem
    primaryImage: IImageAssetContent
  }): ICardProductFrameItem {
    if (frameItem.type === 'content') {
      return {
        ...frameItem,
        content: {
          ...frameItem.content,
          ...primaryImage,
        } as ICardProductFrameImageContent,
      }
    }

    return {
      ...frameItem,
      items: frameItem.items.map((item) =>
        this.updateFrameItemPrimaryImage({
          frameItem: item,
          primaryImage,
        }),
      ),
    }
  }
}
