import {
  takeLeading,
  put,
  select,
  takeEvery,
  actionChannel,
  take,
  call,
} from 'redux-saga/effects'
import { Channel } from 'redux-saga'
import {
  CardProductActionTypes,
  CardProductBackgroundImageName,
  CardProductPageSize,
  EulogiseExportProductName,
  EulogiseProduct,
  ICardProductActionPayload,
  ICardProductBackground,
  ICardProductData,
  ICardProductDataResponse,
  ICardProductFrameLayout,
  ICardProductFrameRow,
  ICardProductOverlayUpdateOptions,
  ICardProductPage,
  ICardProductState,
  ICardProductTheme,
  IEulogiseState,
  MemorialVisualStatus,
  UpdateBackgroundImageMode,
} from '@eulogise/core'
import RequestHelper from '../../helpers/RequestHelper'
import {
  CardProductHelper,
  CaseHelper,
  NavigationHelper,
  SlideshowHelper,
  ThemeHelper,
  UndoHelper,
  UtilHelper,
} from '@eulogise/helpers'
import {
  AddCardProductPageRowAction,
  AddCardProductPagesAction,
  ApplyThemeToAllProductsAction,
  applyThemeToProduct,
  ApplyThemeToProductAction,
  CleanupCardProductEmptyRowsAction,
  ClearPagesContentByPageIndexesAction,
  createCardProductByCaseId,
  CreateCardProductByCaseIdAction,
  DeleteCardProductRowAction,
  downloadCardProductByCaseId,
  DownloadCardProductByCaseIdAction,
  DuplicateCardProductRowAction,
  FetchAllProductsByCaseIdAction,
  fetchCardProductsByCaseId,
  FetchCardProductsByCaseIdAction,
  FetchCardProductUntilProcessedByCaseIdAction,
  GenerateCardProductAction,
  MoveCardProductContentToPageAction,
  ReorderCardProductPageRowsAction,
  RepopulatePrimaryImageAction,
  saveCardProduct,
  SaveCardProductAction,
  UpdateCardProductBackgroundPagesImageAction,
  updateCardProductContent,
  UpdateCardProductContentAction,
  UpdateCardProductContentByContentItemAction,
  UpdateCardProductImageAction,
  UpdateCardProductImageByIdAction,
  updateContentSuccess,
  UpdateContentSuccessAction,
  UpdatePageBackgroundOverlayAction,
  upsertCardProductByCaseId,
  UpsertCardProductByCaseIdAction,
} from './actions'
import { StateHelper } from '../../helpers/StateHelper'
import { Notification } from '@eulogise/client-components'
import { EulogiseClientConfig } from '@eulogise/client-core'
import { DownloadHelper } from '../../helpers/DownloadHelper'
import {
  CardProductContentItemType,
  CardProductViewDisplayMode,
  EulogiseRegion,
  ICardProductColumnsRow,
  ICardProductFrameRowData,
  ICardProductImageRow,
  ICardProductImageType,
  ICardProductRow,
  ResourceFileStatus,
} from '@eulogise/core'
import {
  fetchSlideshowsByCaseId,
  upsertSlideshowByCaseId,
} from '../SlideshowState/actions'
import { CardProductFrameHelper } from '@eulogise/helpers/src'

export const cardProductAction = ({
  type,
  payload = {},
  product,
}: {
  type: string
  payload?: ICardProductActionPayload
  product: EulogiseProduct
}) => ({
  type,
  payload: {
    ...payload,
    productType: product,
  },
})

function* handleFetchCardProductsByCaseId(
  action: FetchCardProductsByCaseIdAction,
) {
  const { payload } = action
  const { product, caseId, region, success } = payload
  const {
    data: { items: products },
  } = yield RequestHelper.findResourceRequest(
    CardProductHelper.getResourceByProduct(product),
    caseId,
  )
  if (!products || products.length === 0) {
    console.log('fetchCardProductsByCaseId products: no products in', product)
    const cardProductAction1 = cardProductAction({
      type: CardProductActionTypes.FETCH_CARD_PRODUCTS_BY_CASE_ID_SUCCESS,
      product,
    })
    yield put(cardProductAction1)
    return
  }

  const activeCardProduct = (products as Array<ICardProductData>)?.[0]
  const activeThemeId = activeCardProduct?.content.theme
  const {
    data: { theme },
  } = yield RequestHelper.fetchThemeById(activeThemeId)

  success && success(products)
  console.log('getProductThemeByProductType', product)
  const cardProductAction1 = cardProductAction({
    type: CardProductActionTypes.FETCH_CARD_PRODUCTS_BY_CASE_ID_SUCCESS,
    payload: {
      products,
      activeProductTheme: ThemeHelper.getProductThemeByProductType({
        theme,
        product,
        region,
      }) as ICardProductTheme,
    },
    product,
  })
  yield put(cardProductAction1)
}

function* handleUpdateCardProductImageById(
  action: UpdateCardProductImageByIdAction,
) {
  try {
    const state: IEulogiseState = yield select((s) => s)
    const {
      payload: { product, imageContent, contentId },
    } = action

    const cardProductState = StateHelper.getProductStateByProduct(
      state,
      product,
    ) as ICardProductState
    const cardProduct = cardProductState.activeItem

    const newProductData = CardProductHelper.updateFrameImageByContentId({
      cardProduct: cardProduct?.content!,
      frameContentId: contentId,
      imageContent,
    })
    console.log('new ProductData', newProductData)
    yield put(updateContentSuccess({ product, pages: newProductData.pages }))
  } catch (ex) {
    console.error(ex)
    throw new Error('Error on handleUpdateCardProductImageById')
  }
}

function* handleSaveCardProduct(action: SaveCardProductAction) {
  const {
    payload: {
      product,
      cardProduct,
      cardProductTheme,
      saveFromClickComplete,
      onSuccess,
    },
  } = action
  const { activeItem: activeCase } = yield select((state) => state.cases)
  const { region } = activeCase

  const productName: string = CardProductHelper.getProductName({
    product,
    region,
  })

  const pages = cardProduct.content.pages
  const updatedCardProduct: ICardProductData = {
    ...cardProduct,
    content: yield CardProductHelper.preCardProductSaveUpdate({
      ...cardProduct.content,
      pages,
    }),
  }

  try {
    let status = updatedCardProduct.status
    if (
      (NavigationHelper.hasProductBeenChanged || saveFromClickComplete) &&
      CardProductHelper.isHigherProductStatusPriority(
        MemorialVisualStatus.EDITED,
        status,
      )
    ) {
      status = MemorialVisualStatus.EDITED
    }
    // @ts-ignore
    const { data }: ICardProductDataResponse =
      yield RequestHelper.saveResourceRequest(
        CardProductHelper.getResourceByProduct(product),
        { ...updatedCardProduct, status },
      )
    yield put(
      cardProductAction({
        type: CardProductActionTypes.SAVE_CARD_PRODUCT_SUCCESS,
        payload: { product: data?.item, cardProductTheme },
        product,
      }),
    )
    if (onSuccess) {
      onSuccess(data.item.id!)
    } else {
      Notification.success(`${productName} saved.`)
    }
    NavigationHelper.removeUnsavedListener()
  } catch (ex) {
    Notification.error(`Failed to save ${productName}.`)
    yield put(
      cardProductAction({
        type: CardProductActionTypes.SAVE_CARD_PRODUCT_FAIL,
        product,
      }),
    )
  }
}

function* handleUpsertCardProductByCaseId(
  action: UpsertCardProductByCaseIdAction,
) {
  const {
    payload: {
      caseId,
      cardProduct,
      product,
      onSuccess,
      region,
      themeId,
      isPopulatingData,
      populatedData,
    },
  } = action

  const {
    data: { theme },
  } = yield RequestHelper.fetchThemeById(themeId)
  const cardProductTheme = ThemeHelper.getProductThemeByProductType({
    product,
    theme,
    region,
  }) as ICardProductTheme

  if (!cardProductTheme) {
    console.log(
      `No cardProductTheme (${product}) theme available for the current theme`,
      theme,
    )
    return
  }

  if (cardProduct) {
    const themeContent = CardProductHelper.createCardProductContentByThemeId({
      product,
      themeId,
      theme,
      isPopulatingData,
      populatedData: populatedData!,
      existingCardProduct: cardProduct,
      region,
    })
    if (product === EulogiseProduct.BOOKLET) {
      const themePages = themeContent.pages
      const themeNoOfPages = themePages.length
      const currentPages = cardProduct.content.pages
      const currentNoOfPages = currentPages.length
      const addedPages = currentPages.slice(
        themeNoOfPages - 1,
        currentNoOfPages - 1,
      )
      const secondPage = themePages[1]
      const thirdPage = themePages[2]
      const pages = [
        ...themePages.slice(0, themeNoOfPages - 1),
        ...addedPages.map((p: ICardProductPage, index: number) => {
          const { background, border, ...pageContent } = p
          if (index % 2 === 0) {
            return {
              ...secondPage,
              ...pageContent,
            }
          }
          return {
            ...thirdPage,
            ...pageContent,
          }
        }),
        themePages[themeNoOfPages - 1],
      ]
      yield put(
        saveCardProduct({
          product,
          cardProductTheme,
          cardProduct: {
            ...cardProduct,
            content: { ...themeContent, pages },
            case: caseId,
          },
          onSuccess,
        }),
      )
    } else {
      yield put(
        saveCardProduct({
          product,
          cardProductTheme,
          cardProduct: {
            ...cardProduct,
            content: themeContent,
            case: caseId,
          },
          onSuccess,
        }),
      )
    }
    return
  }
  yield put(
    createCardProductByCaseId({
      product,
      cardProductTheme,
      caseId,
      themeId: themeId.toLowerCase(),
      theme,
      isPopulatingData,
      populatedData: populatedData!,
      region,
      onSuccess,
    }),
  )
}

function* handleDownloadCardProductByCaseId(
  action: DownloadCardProductByCaseIdAction,
) {
  const {
    payload: {
      product,
      caseId,
      productName,
      isBleed,
      // There is a delay on the file made available in S3 after the status is updated to GENERATED
      // That's why we need so many retries
      retries = 20,
      deceasedName,
      complete,
    },
  } = action
  try {
    const fileType =
      product === EulogiseProduct.TV_WELCOME_SCREEN ? 'jpg' : 'pdf'
    const url = `${
      EulogiseClientConfig.AWS_S3_URL_WITHOUT_CDN
    }/cases/${caseId}/${
      productName === EulogiseExportProductName.BOOKLET_US
        ? EulogiseExportProductName.BOOKLET
        : productName
    }${isBleed ? '-bleed' : ''}.${fileType}`
    yield RequestHelper.updateResourceRequest(
      CardProductHelper.getResourceByProduct(product),
      {
        case: caseId,
        status: MemorialVisualStatus.DOWNLOAD,
      },
    )
    const downloadProductName =
      product === EulogiseProduct.SIDED_CARD ? 'Memorial Card' : productName
    yield DownloadHelper.downloadAs(
      `${url}?time=${new Date().getTime()}`,
      `${deceasedName}'s ${downloadProductName}${
        isBleed ? ' with Bleed' : ''
      }.${fileType}`,
    )
  } catch (ex) {
    console.log('failed to download')
    if (!!retries) {
      const ms = 1000
      console.log(`retry in ${ms}ms`)
      yield UtilHelper.sleep(ms)
      yield put(
        downloadCardProductByCaseId({
          product,
          productName,
          caseId,
          deceasedName,
          isBleed,
          retries: retries - 1,
        }),
      )
    }
  }
  if (complete) {
    complete()
  }
}

function* handleCreateCardProductByCaseId(
  action: CreateCardProductByCaseIdAction,
) {
  const {
    payload: {
      product,
      caseId,
      isPopulatingData,
      populatedData,
      onSuccess,
      cardProductTheme,
      region,
      theme,
      themeId,
    },
  } = action

  try {
    const productContent = CardProductHelper.createCardProductContentByThemeId({
      product,
      themeId,
      theme,
      isPopulatingData,
      populatedData,
      region,
    })
    const productPages = productContent.pages
    const cardProduct: ICardProductData = {
      status: MemorialVisualStatus.THEME_SELECTED,
      case: caseId,
      content: yield CardProductHelper.preCardProductSaveUpdate({
        ...productContent,
        pages: productPages,
      }),
    }

    const { data } = yield RequestHelper.saveResourceRequest(
      CardProductHelper.getResourceByProduct(product),
      cardProduct,
    )
    yield put(
      cardProductAction({
        type: CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID_SUCCESS,
        payload: { product: data.item, cardProductTheme },
        product,
      }),
    )
    if (onSuccess) {
      onSuccess(data.item.id)
    }
    NavigationHelper.removeUnsavedListener()
  } catch (ex: any) {
    console.log('ex', ex)
    yield put(
      cardProductAction({
        type: CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID_FAILED,
        payload: {
          ex,
        },
        product,
      }),
    )
  }
}

function* handleUpdateCardProductBackgroundPagesImage(
  action: UpdateCardProductBackgroundPagesImageAction,
) {
  const {
    payload: { product, cardProduct, updateMode, backgroundImageSet, noSave },
  } = action

  if (updateMode === UpdateBackgroundImageMode.NOT_APPLICABLE) {
    return
  }

  const cardProductNewBackgroundImages =
    backgroundImageSet?.cardProducts?.[product]

  const currentPages: Array<ICardProductPage> = cardProduct?.content?.pages

  const updatedPageSize: CardProductPageSize = cardProduct?.content?.pageSize

  const numberOfPages: number = currentPages?.length

  const updatedPages: Array<ICardProductPage> = currentPages.map(
    (page: ICardProductPage, index: number) => {
      // clean the middle pages background images content
      if (
        updateMode === UpdateBackgroundImageMode.UPDATE_COVER_PAGES_ONLY &&
        index > 0 &&
        index < numberOfPages - 1 &&
        !(
          product === EulogiseProduct.THANK_YOU_CARD ||
          product === EulogiseProduct.TV_WELCOME_SCREEN
        )
      ) {
        const removedBackgroundImagePage: ICardProductPage = page
        delete removedBackgroundImagePage?.background
        return removedBackgroundImagePage
      }

      let newBackgroundImageUrl
      const pageBackgroundImageData: ICardProductBackground = page?.background!

      // Sided card - 2 pages
      if (
        updateMode === UpdateBackgroundImageMode.UPDATE_FRONT_PAGE_ONLY &&
        product === EulogiseProduct.SIDED_CARD
      ) {
        if (index === 0) {
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.FRONT]
        } else {
          const removedBackgroundImagePage: ICardProductPage = page
          delete removedBackgroundImagePage?.background
          return removedBackgroundImagePage
        }
      }

      // Bookmark - 2 pages
      if (product === EulogiseProduct.BOOKMARK) {
        if (index === 0) {
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.FRONT]
        } else if (
          index === numberOfPages - 1 &&
          updateMode === UpdateBackgroundImageMode.UPDATE_COVER_PAGES_ONLY
        ) {
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.BACK]
        } else {
          const removedBackgroundImagePage: ICardProductPage = page
          delete removedBackgroundImagePage?.background
          return removedBackgroundImagePage
        }
      }

      // Thank you card and TV screen: Edge cases - 2 col layouts:
      if (
        (product === EulogiseProduct.THANK_YOU_CARD &&
          updatedPageSize === CardProductPageSize.THANKYOUCARD_2_COLS) ||
        (product === EulogiseProduct.TV_WELCOME_SCREEN &&
          updatedPageSize === CardProductPageSize.TV_WELCOME_SCREEN_2_COLS)
      ) {
        if (index === 0) {
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.LEFT]
        } else if (index === 1) {
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.RIGHT]
        }
      } else {
        // Normal card product layout --- 1 col layout page:
        if (index === 0) {
          // update the front page
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.FRONT]
        } else if (index === numberOfPages - 1) {
          // update the back page
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.BACK]
        } else if (index % 2 === 1) {
          // update the left page
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.LEFT]
        } else if (index % 2 === 0) {
          // update the right page
          newBackgroundImageUrl =
            cardProductNewBackgroundImages[CardProductBackgroundImageName.RIGHT]
        }
      }

      // clean and do not update when no url from background image set
      if (!newBackgroundImageUrl) {
        const removedBackgroundImagePage: ICardProductPage = page
        delete removedBackgroundImagePage?.background
        return removedBackgroundImagePage
      }

      const updatedPageBackgroundImageData: ICardProductBackground = {
        ...pageBackgroundImageData,
        image: {
          ...pageBackgroundImageData?.image,
          filepath: newBackgroundImageUrl,
        },
      }

      const newPage: ICardProductPage = {
        ...page,
        background: updatedPageBackgroundImageData,
      }

      return newPage
    },
  )

  const updatedCardProduct: ICardProductData = {
    ...cardProduct,
    content: {
      ...cardProduct?.content,
      pages: updatedPages,
      pageSize: updatedPageSize,
    },
  }

  yield put(updateContentSuccess({ product, pages: updatedPages }))
  if (noSave) {
    return
  }
  yield put(saveCardProduct({ product, cardProduct: updatedCardProduct }))
}

function* handleUpdatePageBackgroundOverlay(
  action: UpdatePageBackgroundOverlayAction,
) {
  const {
    payload: {
      product,
      cardProduct,
      updatedPageIndex,
      cardProductOverlayOptions,
    },
  } = action
  const currentPages: Array<ICardProductPage> = cardProduct?.content?.pages
  if (updatedPageIndex === undefined || isNaN(updatedPageIndex)) {
    return
  }
  const {
    overlayColor,
    overlayMargin,
    overlayOpacity,
  }: ICardProductOverlayUpdateOptions = cardProductOverlayOptions

  const updatedPages = currentPages.map(
    (page: ICardProductPage, index: number) => {
      if (index !== updatedPageIndex) {
        return page
      }

      const pageBackgroundImageData: ICardProductBackground = page?.background!
      // Toggle off overlay data for current page's background
      if (
        pageBackgroundImageData &&
        pageBackgroundImageData?.overlayColor &&
        pageBackgroundImageData?.overlayMargin &&
        pageBackgroundImageData?.overlayOpacity
      ) {
        const removedPage: ICardProductPage = page
        const removedBackgroundImageData = removedPage?.background

        const {
          overlayColor,
          overlayMargin,
          overlayOpacity,
          ...newBackgroundPageData
        } = removedBackgroundImageData
        const newBackgroundPage = {
          ...removedPage,
          background: newBackgroundPageData,
        }

        return newBackgroundPage
      }

      const updatedPageBackgroundImageData: ICardProductBackground = {
        ...pageBackgroundImageData,
        overlayColor,
        overlayMargin,
        overlayOpacity,
      }
      const newPage: ICardProductPage = {
        ...page,
        background: updatedPageBackgroundImageData,
      }
      return newPage
    },
  )
  yield put(updateContentSuccess({ product, pages: updatedPages }))
}

function* handleFetchCardProductUntilProcessedByCaseId(
  action: FetchCardProductUntilProcessedByCaseIdAction,
) {
  const {
    payload: { product, caseId, region },
  } = action
  const interval = setInterval(() => {
    put(
      fetchCardProductsByCaseId({
        product,
        caseId,
        region,
        success: (booklets: Array<ICardProductData>) => {
          if (booklets.length === 0) {
            clearInterval(interval)
          }
          booklets.forEach((booklet: ICardProductData) => {
            if (booklet.fileStatus !== ResourceFileStatus.PROCESSING) {
              clearInterval(interval)
            }
          })
        },
      }),
    )
  }, 5000)
}

function* handleGenerateCardProduct(action: GenerateCardProductAction) {
  const {
    payload: { product, cardProductId, caseId },
  } = action
  const { activeItem: activeCase } = yield select((state) => state.cases)
  const { account } = yield select((state) => state.auth)
  const region = activeCase?.region

  try {
    const { status } = yield RequestHelper.generateResourceRequest({
      product,
      productId: cardProductId,
      data: {
        generateUserId: account?.id,
        region,
      },
    })
    console.log('card product resp', status)
    yield put(
      cardProductAction({
        type: CardProductActionTypes.GENERATE_CARD_PRODUCT_SUCCESS,
        product,
      }),
    )
  } catch (ex) {
    console.log('Exception', ex)
    /* hide notification since the process is asynchronous
    Notification.error(
      `Failed to generate ${CardProductHelper.getProductShortName({
        product,
        region,
      })}`,
    )*/
    yield put(
      cardProductAction({
        type: CardProductActionTypes.GENERATE_CARD_PRODUCT_FAILED,
        product,
      }),
    )
  }
}

function* handleAddCardProductPages(action: AddCardProductPagesAction) {
  const {
    payload: { onSuccess },
  } = action
  Notification.success('Pages added')
  if (onSuccess) {
    onSuccess()
  }
}

function* handleRemoveCardProductPages() {
  Notification.success('Pages removed')
}

function* handleAddCardProductPageRow(action: AddCardProductPageRowAction) {
  const {
    payload: {
      product,
      pageIndex,
      success,
      type,
      productTheme,
      options: { content, region, ...restOptions } = {},
    },
  } = action

  // @ts-ignore
  const newRow: ICardProductRow = {
    id: UtilHelper.generateID(8),
    type,
    data: CardProductHelper.createRowData(product, type, productTheme, {
      content,
      region,
      ...restOptions,
    }),
  }

  yield put(
    cardProductAction({
      type: CardProductActionTypes.ADD_CARD_PRODUCT_PAGE_ROW_SUCCESS,
      payload: {
        pageIndex,
        type,
        row: newRow,
      },
      product,
    }),
  )
  success && success(newRow)
}

function* handleCleanupCardProductEmptyRows(
  action: CleanupCardProductEmptyRowsAction,
) {
  const {
    payload: { product, cardProduct, pageIndex },
  } = action
  const page = cardProduct.content.pages[pageIndex]
  const updatedRows = page.rows.filter((row: ICardProductRow) => {
    if (row.type === CardProductContentItemType.IMAGE) {
      if (row.data.filename === '') {
        return false
      }
    } else if (row.type === CardProductContentItemType.COLUMNS) {
      const isAllEmpty: boolean = row.data.items.reduce(
        (curr: boolean, i: ICardProductImageRow) => {
          if (!curr) {
            return curr
          }
          return i.data.filename === ''
        },
        true,
      )!
      return !isAllEmpty
    }
    /* DON'T clean up empty spaces
    else if (row.type === CardProductContentItemType.SPACE) {
      if (!row.data.divider) {
        return false
      }
    }*/
    return true
  })

  const pages = CardProductHelper.updateCardProductPages({
    page,
    updatedRows,
    cardProduct,
    pageIndex,
  })

  yield put(updateContentSuccess({ product, pages }))
}

function* handleDeleteCardProductRow(action: DeleteCardProductRowAction) {
  const {
    payload: { product, cardProduct, id, pageIndex },
  } = action
  const page = cardProduct.content.pages[pageIndex]
  const updatedRows = page.rows.filter((row: ICardProductRow) => row.id !== id)

  const pages = CardProductHelper.updateCardProductPages({
    page,
    updatedRows,
    cardProduct,
    pageIndex,
  })
  yield put(updateContentSuccess({ product, pages }))
}

function* handleDuplicateCardProductRow(action: DuplicateCardProductRowAction) {
  const {
    payload: { product, cardProduct, pageIndex, id },
  } = action
  const page = cardProduct.content.pages[pageIndex]!

  const pageRows = page?.rows

  const originalRow: ICardProductRow = page.rows.find(
    (row: ICardProductRow) => row.id === id,
  )!
  if (!originalRow) {
    return
  }
  const newRow: ICardProductRow = {
    ...originalRow,
    id: UtilHelper.generateID(8),
  }

  const originalRowIndex: number = page.rows.indexOf(originalRow)

  const updatedRows: Array<ICardProductRow> = CardProductHelper.insertRowInPage(
    {
      originalPageRows: pageRows,
      insertRowIndex: originalRowIndex,
      insertNewRow: newRow,
    },
  )

  const pages = CardProductHelper.updateCardProductPages({
    page,
    updatedRows,
    cardProduct,
    pageIndex,
  })

  yield put(updateContentSuccess({ product, pages }))
}

function* handleMoveCardProductContentToPage(
  action: MoveCardProductContentToPageAction,
) {
  const {
    payload: { product, source, cardProductContent, destination },
  } = action

  const { pages } = cardProductContent
  const sourceIndex = CardProductHelper.getIndexFromId(source.droppableId)
  const destinationIndex = CardProductHelper.getIndexFromId(
    destination.droppableId,
  )
  const sourceList = pages[sourceIndex].rows
  const destinationList = pages[destinationIndex].rows

  const result = CardProductHelper.moveContent(
    sourceList,
    destinationList,
    source,
    destination,
  )
  if (
    !CardProductHelper.contentCanFit(
      result[destination.droppableId],
      cardProductContent,
    )
  ) {
    return // TODO: show error message to user that content doesn't fit on the destination page
  } else {
    /*
      dispatch(cardProductAction({
        type: BookletActionTypes.CONTENT_IS_NOT_FULL,
        payload: { pageIndex: destinationIndex },
        product
      }))
*/
  }

  const newPages = pages.map((page: ICardProductPage, index: number) => {
    if (index === sourceIndex) {
      return { ...page, rows: result[source.droppableId] }
    }

    if (index === destinationIndex) {
      return { ...page, rows: result[destination.droppableId] }
    }

    return page
  })

  yield put(updateContentSuccess({ product, pages: newPages }))
}

function* handleReorderCardProductPageRows(
  action: ReorderCardProductPageRowsAction,
) {
  const {
    payload: { product, cardProductContent, source, destination },
  } = action
  const sourceIndex = CardProductHelper.getIndexFromId(source.droppableId)
  const reorderedRows = CardProductHelper.reorder(
    cardProductContent.pages[sourceIndex].rows,
    source.index,
    destination.index,
  )
  const pages = cardProductContent.pages.map(
    (page: ICardProductPage, index: number) => {
      if (index === sourceIndex) {
        return { ...page, rows: reorderedRows }
      }

      return page
    },
  )
  yield put(updateContentSuccess({ product, pages }))
}

function* handleResetAllCardProductState() {
  yield put(
    cardProductAction({
      type: CardProductActionTypes.RESET_CARD_PRODUCT_STATE,
      product: EulogiseProduct.BOOKLET,
    }),
  )
  yield put(
    cardProductAction({
      type: CardProductActionTypes.RESET_CARD_PRODUCT_STATE,
      product: EulogiseProduct.BOOKMARK,
    }),
  )
  yield put(
    cardProductAction({
      type: CardProductActionTypes.RESET_CARD_PRODUCT_STATE,
      product: EulogiseProduct.SIDED_CARD,
    }),
  )
  yield put(
    cardProductAction({
      type: CardProductActionTypes.RESET_CARD_PRODUCT_STATE,
      product: EulogiseProduct.THANK_YOU_CARD,
    }),
  )
  yield put(
    cardProductAction({
      type: CardProductActionTypes.RESET_CARD_PRODUCT_STATE,
      product: EulogiseProduct.TV_WELCOME_SCREEN,
    }),
  )
}

function* handleApplyThemeToProduct(action: ApplyThemeToProductAction) {
  const {
    payload: {
      product,
      themeId,
      cardProduct,
      onSuccess,
      populatedData,
      isPopulatingData,
      activeCase,
      slideshow,
    },
  } = action
  const caseId: string = activeCase?.id
  const region: EulogiseRegion = activeCase?.region ?? EulogiseRegion.AU
  if (product === EulogiseProduct.SLIDESHOW) {
    yield put(
      upsertSlideshowByCaseId({
        caseId,
        slideshow,
        themeId,
        deceasedFullName: activeCase.deceased.fullName,
        deceasedLifeString: `${populatedData?.dateOfBirth} - ${populatedData?.dateOfDeath}`,
        onSuccess,
      }),
    )
  } else if (CardProductHelper.isCardProduct(product)) {
    yield put(
      upsertCardProductByCaseId({
        product,
        caseId,
        cardProduct,
        region,
        themeId,
        isPopulatingData,
        populatedData,
        onSuccess,
      }),
    )
  }
}

function* handleApplyThemeToAllProducts(action: ApplyThemeToAllProductsAction) {
  const {
    payload: {
      themeId,
      isPopulatingData,
      activeCase,
      onSuccess,
      populatedData,
      cardProducts,
      slideshow,
    },
  } = action
  const isSlideshowEnabled = CaseHelper.isCaseProductEnabled(
    activeCase,
    EulogiseProduct.SLIDESHOW,
  )
  if (
    isSlideshowEnabled &&
    SlideshowHelper.isSlideshowThemeChangable(slideshow!)
  ) {
    yield put(
      applyThemeToProduct({
        product: EulogiseProduct.SLIDESHOW,
        activeCase,
        slideshow,
        themeId,
        isPopulatingData,
        populatedData,
        onSuccess: (productId: string) => {
          if (onSuccess) {
            onSuccess(EulogiseProduct.SLIDESHOW, productId)
          }
        },
      }),
    )
  }
  for (let [product, cardProduct] of Object.entries(cardProducts!)) {
    if (CardProductHelper.isCardProductThemeChangable(cardProduct)) {
      yield put(
        applyThemeToProduct({
          product,
          activeCase,
          themeId,
          cardProduct,
          isPopulatingData,
          populatedData,
          onSuccess: (productId: string) => {
            if (onSuccess) {
              onSuccess(product as EulogiseProduct, productId)
            }
          },
        }),
      )
    }
  }
}

function* handleUpdateCardProductSpaceAssetByRowId() {}

function* handleUpdateCardProductImage(action: UpdateCardProductImageAction) {
  const {
    payload: {
      product,
      cardProduct,
      image,
      frameContentItemId,
      pageIndex,
      columnIndex,
      rowId,
    },
  } = action
  // only trigger save on single image as it is potentially primary image
  if (cardProduct) {
    yield put(
      saveCardProduct({
        product,
        cardProduct: CardProductHelper.getUpdateBookletRowState({
          cardProductState: UndoHelper.recordUndoContentList({
            activeItem: cardProduct,
          }),
          frameContentItemId,
          pageIndex,
          rowId,
          rowData: image,
        }).activeItem,
      }),
    )
  }
}

function* handleRepopulatePrimaryImage(action: RepopulatePrimaryImageAction) {
  const {
    payload: {
      product,
      primaryImage,
      cardProduct,
      region,
      defaultThemeLayoutColumns,
      cardProductViewDisplayMode,
    },
  } = action
  if (cardProductViewDisplayMode !== CardProductViewDisplayMode.EDIT) {
    return
  }
  let hasPopulatePrimaryImage = false
  const newCardProductData: ICardProductData = {
    ...cardProduct,
    content: {
      ...cardProduct?.content!,
      pages: cardProduct?.content.pages.map((p: ICardProductPage) => {
        return {
          ...p,
          rows: p.rows.map((r: ICardProductRow) => {
            if (r.type === CardProductContentItemType.IMAGE) {
              if (
                r.data.imageType === ICardProductImageType.DEFAULT_THEME_IMAGE
              ) {
                hasPopulatePrimaryImage = true
                const {
                  height: adjustedImageHeight,
                  width: adjustedImageWidth,
                } = CardProductHelper.getImageSizeWithPresetHeight({
                  defaultPrimaryImageHeight: r.data.height,
                  defaultThemeLayoutColumns,
                  primaryImageHeight: primaryImage.height,
                  primaryImageWidth: primaryImage.width,
                  product,
                  region,
                })
                return {
                  ...r,
                  data: {
                    ...r.data,
                    filename: primaryImage.filename,
                    filepath: primaryImage.filepath,
                    filestackHandle: primaryImage.filestackHandle,
                    height: adjustedImageHeight,
                    width: adjustedImageWidth,
                    imageType: ICardProductImageType.PRIMARY_IMAGE, // set it to Current Image to ensure only populate once
                  },
                }
              }
            } else if (r.type === CardProductContentItemType.FRAME) {
              hasPopulatePrimaryImage = true
              const defaultContent =
                CardProductHelper.getDefaultDeceasedContentByThemeId({
                  product,
                  themeId: cardProduct.content.theme,
                  region,
                })!

              const newFrameContent =
                CardProductHelper.createPrimaryImageTemplateObject({
                  defaultPrimaryImage: defaultContent.primaryImage!,
                  product,
                  primaryImage,
                  defaultThemeLayoutColumns,
                  region,
                })
              console.log('newFrameContent', newFrameContent)
              return {
                ...r,
                data: {
                  ...r.data,
                  content: newFrameContent.content,
                  /*
                  content: CardProductFrameHelper.updateFrameItemPrimaryImage(
                    {
                      frameItem: r.data.content,
                      primaryImage,
                    },
                  ),
*/
                } as ICardProductFrameRowData,
              }
            }
            return r
          }),
        }
      })!,
    },
  }
  if (hasPopulatePrimaryImage) {
    yield put(saveCardProduct({ product, cardProduct: newCardProductData }))
  }
}

function* handleUpdateCardProductContentByContentItemId(
  action: UpdateCardProductContentByContentItemAction,
) {
  const {
    payload: { product, isAddToUndoList, contentItem, pageIndex, rowId },
  } = action
  try {
    const { activeItem: cardProduct } = yield select((s) =>
      StateHelper.getProductStateByProduct(s, product),
    )
    const row = CardProductHelper.getRowByPagesAndRowId({
      pages: cardProduct.content.pages,
      rowId,
    }) as ICardProductFrameRow
    const newLayout = CardProductFrameHelper.getUpdatedLayoutFromNewContentItem(
      row?.data.content as ICardProductFrameLayout,
      contentItem,
    )
    yield put(
      updateCardProductContent({
        product,
        pageIndex,
        rowId,
        isAddToUndoList,
        data: {
          ...cardProduct,
          content: newLayout,
        },
      }),
    )
  } catch (ex) {
    console.log('Exception', ex)
  }
}

function* handleUpdateCardProductContent(
  action: UpdateCardProductContentAction,
) {
  const {
    payload: { product, pageIndex, data, type, rowId, isAddToUndoList },
  } = action
  try {
    const { activeItem: cardProduct } = yield select((s) =>
      StateHelper.getProductStateByProduct(s, product),
    )

    const page = cardProduct?.content.pages[pageIndex]

    const updatedRows = page.rows
      .map((row: ICardProductRow) => {
        if (row.id === rowId) {
          if (!data) {
            return false
          }
          if (row.type === CardProductContentItemType.COLUMNS) {
            // @ts-ignore
            const dataItems = data.items
            return {
              ...row,
              data: {
                items: (row as ICardProductColumnsRow).data.items.map(
                  (rowItem: ICardProductImageRow, rowItemIndex: number) => {
                    return {
                      data: {
                        ...rowItem.data,
                        ...dataItems[rowItemIndex].data,
                      },
                    }
                  },
                ),
              },
            }
          }
          return UtilHelper.mergeDeepRight(row, {
            data,
            type: type ?? row.type,
          })
        }

        return row
      })
      .filter(Boolean)

    const pages = CardProductHelper.updateCardProductPages({
      page,
      updatedRows,
      cardProduct,
      pageIndex,
    })

    console.log('fire update content success', { product, pages })
    yield put(
      updateContentSuccess({
        product,
        pages,
        options: { pageIndex, isAddToUndoList },
      }),
    )
  } catch (ex) {
    console.log('ex', ex)
    throw new Error('Failed to update card product content')
  }
}

function* handleUpdateCardProductContentSuccess(
  action: UpdateContentSuccessAction,
) {
  const {
    payload: { product, pages, options },
  } = action
  const pageIndex = options?.pageIndex
  const isAddToUndoList = options?.isAddToUndoList ?? true

  yield put(
    cardProductAction({
      type: CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT_SUCCESS_COMPLETE,
      payload: pageIndex
        ? { pages, pageIndex, isAddToUndoList }
        : { pages, isAddToUndoList },
      product,
    }),
  )
}

function* handleFetchAllProductsByCaseId(
  action: FetchAllProductsByCaseIdAction,
) {
  const {
    payload: { caseId, region },
  } = action
  yield put(
    fetchCardProductsByCaseId({
      product: EulogiseProduct.BOOKLET,
      caseId,
      region,
    }),
  )
  yield put(
    fetchCardProductsByCaseId({
      product: EulogiseProduct.BOOKMARK,
      caseId,
      region,
    }),
  )
  yield put(fetchSlideshowsByCaseId({ caseId }))
  yield put(
    fetchCardProductsByCaseId({
      product: EulogiseProduct.SIDED_CARD,
      caseId,
      region,
    }),
  )
  yield put(
    fetchCardProductsByCaseId({
      product: EulogiseProduct.THANK_YOU_CARD,
      caseId,
      region,
    }),
  )
  yield put(
    fetchCardProductsByCaseId({
      product: EulogiseProduct.TV_WELCOME_SCREEN,
      caseId,
      region,
    }),
  )
}

function* handleClearPagesContentByPageIndexes(
  action: ClearPagesContentByPageIndexesAction,
) {
  const {
    payload: { product, newRowsData, cardProduct, blankPagesIndexes },
  } = action
  const updatedPages = cardProduct.content.pages?.map(
    (p: ICardProductPage, index: number) => {
      if (blankPagesIndexes.includes(index)) {
        const updatedPage: ICardProductPage = {
          ...p,
          rows: newRowsData.map((newRowData) => {
            return {
              ...newRowData,
              id: UtilHelper.generateID(8),
            }
          }),
        }
        return updatedPage
      }
      return p
    },
  )
  yield put(updateContentSuccess({ product, pages: updatedPages }))
}

/* Watchers */
function* watchers() {
  yield takeEvery(
    CardProductActionTypes.FETCH_CARD_PRODUCTS_BY_CASE_ID,
    handleFetchCardProductsByCaseId,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_BY_IMAGE_ID,
    handleUpdateCardProductImageById,
  )
  yield takeEvery(
    CardProductActionTypes.SAVE_CARD_PRODUCT,
    handleSaveCardProduct,
  )
  yield takeEvery(
    CardProductActionTypes.UPSERT_CARD_PRODUCT_BY_CASE_ID,
    handleUpsertCardProductByCaseId,
  )
  yield takeEvery(
    CardProductActionTypes.DOWNLOAD_CARD_PRODUCT_BY_CASE_ID,
    handleDownloadCardProductByCaseId,
  )
  yield takeEvery(
    CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID,
    handleCreateCardProductByCaseId,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_BACKGROUND_PAGES_IMAGE,
    handleUpdateCardProductBackgroundPagesImage,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_PAGE_BACKGROUND_OVERLAY,
    handleUpdatePageBackgroundOverlay,
  )
  yield takeEvery(
    CardProductActionTypes.FETCH_CARD_PRODUCT_UNTIL_PROCESSED_BY_CASE_ID,
    handleFetchCardProductUntilProcessedByCaseId,
  )
  yield takeEvery(
    CardProductActionTypes.GENERATE_CARD_PRODUCT,
    handleGenerateCardProduct,
  )
  yield takeEvery(
    CardProductActionTypes.ADD_CARD_PRODUCT_PAGES,
    handleAddCardProductPages,
  )
  yield takeEvery(
    CardProductActionTypes.REMOVE_CARD_PRODUCT_PAGES,
    handleRemoveCardProductPages,
  )
  yield takeEvery(
    CardProductActionTypes.ADD_CARD_PRODUCT_PAGE_ROW,
    handleAddCardProductPageRow,
  )
  yield takeEvery(
    CardProductActionTypes.CLEANUP_CARD_PRODUCT_EMPTY_ROWS,
    handleCleanupCardProductEmptyRows,
  )
  yield takeEvery(
    CardProductActionTypes.DELETE_CARD_PRODUCT_ROW,
    handleDeleteCardProductRow,
  )
  yield takeEvery(
    CardProductActionTypes.DUPLICATE_CARD_PRODUCT_ROW,
    handleDuplicateCardProductRow,
  )
  yield takeEvery(
    CardProductActionTypes.MOVE_CARD_PRODUCT_CONTENT_TO_PAGE,
    handleMoveCardProductContentToPage,
  )
  yield takeEvery(
    CardProductActionTypes.REORDER_CARD_PRODUCT_PAGE_ROWS,
    handleReorderCardProductPageRows,
  )
  yield takeEvery(
    CardProductActionTypes.RESET_ALL_CARD_PRODUCT_STATE,
    handleResetAllCardProductState,
  )
  yield takeEvery(
    CardProductActionTypes.APPLY_THEME_TO_PRODUCT,
    handleApplyThemeToProduct,
  )
  yield takeEvery(
    CardProductActionTypes.APPLY_THEME_TO_ALL_PRODUCTS,
    handleApplyThemeToAllProducts,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_SPACE_ASSET_BY_ROW_ID,
    handleUpdateCardProductSpaceAssetByRowId,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_IMAGE,
    handleUpdateCardProductImage,
  )
  yield takeEvery(
    CardProductActionTypes.REPOPULATE_PRIMARY_IMAGE,
    handleRepopulatePrimaryImage,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT,
    handleUpdateCardProductContent,
  )
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT_SUCCESS,
    handleUpdateCardProductContentSuccess,
  )
  yield takeEvery(
    CardProductActionTypes.FETCH_ALL_PRODUCTS_BY_CASE_ID,
    handleFetchAllProductsByCaseId,
  )
  yield takeLeading(
    CardProductActionTypes.CLEAR_PAGES_CONTENT_BY_PAGE_INDEXES,
    handleClearPagesContentByPageIndexes,
  )
}

function* updateContentWatcher() {
  // Create a channel to queue actions
  const requestChannel: Channel<UpdateCardProductContentAction> =
    yield actionChannel(
      CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT_BY_CONTENT_ITEM,
    )

  while (true) {
    // Wait for the next action from the channel
    const action: UpdateCardProductContentAction = yield take(requestChannel)

    // Process the action (serially)
    yield call(handleUpdateCardProductContentByContentItemId, action)
  }
  /*
  yield takeEvery(
    CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT,
    handleUpdateCardProductContent,
  )
*/
}

export const CardProductSagas = [watchers(), updateContentWatcher()]
