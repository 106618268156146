import {
  EulogiseRegion,
  EulogiseCountry,
  ICase,
  INITIAL_DEFAULT_PRODUCTS,
  EulogiseProduct,
  IEulogiseProductAvailabilityStatus,
} from '@eulogise/core'

export enum CaseSearchType {
  EMAIL = 'EMAIL',
  UUID = 'UUID',
}

export class CaseHelper {
  public static getCaseReportS3Path() {
    return 'reports/caseReport.json'
  }

  public static getSearchType = (query: string): CaseSearchType | undefined => {
    const isEmailSearch =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(query)
    const isUuidSearch =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
        query,
      )
    if (isEmailSearch) {
      return CaseSearchType.EMAIL
    } else if (isUuidSearch) {
      return CaseSearchType.UUID
    }
    return
  }

  public static getEnabledProducts(icase: ICase) {
    return icase?.enabledProducts ?? INITIAL_DEFAULT_PRODUCTS
  }

  public static getIsDisplayTwoColumns(icase: ICase) {
    const noOfEnabledProducts = this.getNoOfEnabledProducts(icase)
    return noOfEnabledProducts < 6 && noOfEnabledProducts % 2 === 0
  }

  public static getNoOfEnabledProducts(icase: ICase) {
    const enabledProducts = {
      ...this.getEnabledProducts(icase),

      // remove photobook from the list
      PHOTOBOOK: false,
    }
    return enabledProducts
      ? Object.values(enabledProducts).filter(Boolean).length
      : 0
  }

  public static isProductEnabled(
    enabledProducts: IEulogiseProductAvailabilityStatus,
    product: EulogiseProduct,
  ) {
    return enabledProducts[product]
  }

  public static isCaseProductEnabled(icase: ICase, product: EulogiseProduct) {
    const enabledProducts = this.getEnabledProducts(icase)
    return this.isProductEnabled(enabledProducts, product)
  }

  public static getRegionByCountry({
    country,
  }: {
    country: EulogiseCountry
  }): EulogiseRegion {
    const US_REGION_COUNTRIES = [
      EulogiseCountry.UNITED_STATES,
      EulogiseCountry.CANADA,
      EulogiseCountry.CHILE,
      EulogiseCountry.COLOMBIA,
      EulogiseCountry.COSTA_RICA,
      EulogiseCountry.MEXICO,
      EulogiseCountry.PANAMA,
      EulogiseCountry.GUATEMALA,
      EulogiseCountry.THE_DOMINICAN_REPUBLIC,
      EulogiseCountry.THE_PHILIPPINES,
      EulogiseCountry.REST_OF_THE_WOLRD,
    ]
    if (US_REGION_COUNTRIES.includes(country)) {
      return EulogiseRegion.USA
    }
    return EulogiseRegion.AU
  }
}
