import { navigate } from 'gatsby-link'
import {
  EulogisePage,
  EulogiseProduct,
  INavigateToProductQueryString,
} from '@eulogise/core'
import { UtilHelper } from './UtilHelper'
import { UrlHelper } from './UrlHelper'

export const NavigationHelper = {
  hasProductBeenChanged: false,
  hasAddedUnsavedListener: false,

  isClientDashboardPage: (urlOrPath: string) => {
    return new RegExp(/\/admin\/client/).test(urlOrPath)
  },

  isProductPage: (url: string) => {
    const productPages = [
      EulogisePage.EDIT_BOOKLET,
      EulogisePage.EDIT_BOOKMARK,
      EulogisePage.EDIT_SLIDESHOW,
      EulogisePage.EDIT_THANK_YOU_CARD,
      EulogisePage.EDIT_SIDED_CARD,
      EulogisePage.EDIT_TV_WELCOME_SCREEN,
    ]
    return productPages.some((page) => {
      return (
        new RegExp(page.replace(/:[a-zA-Z]+/, '')).test(url) &&
        url.split('/')?.length === 4
      )
    })
  },

  navigateToBackground: ({
    product,
    backgroundImageId,
    fromProduct,
    fromProductId,
  }: {
    product: EulogiseProduct
    backgroundImageId: string
    fromProduct: EulogiseProduct
    fromProductId: string
  }) => {
    if (product === EulogiseProduct.BOOKLET) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_BOOKLET_BACKGROUND_IMAGE,
        {
          backgroundImageId,
        },
        {
          fromProduct,
          fromProductId,
        },
      )
    }
  },

  navigateToProduct: (
    product: EulogiseProduct,
    id: string,
    query?: INavigateToProductQueryString,
    ignoreUnsavedConfirmation?: boolean,
  ) => {
    if (product === EulogiseProduct.SLIDESHOW) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_SLIDESHOW,
        {
          slideshowId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    } else if (product === EulogiseProduct.BOOKLET) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_BOOKLET,
        {
          bookletId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    } else if (product === EulogiseProduct.BOOKMARK) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_BOOKMARK,
        {
          bookmarkId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    } else if (product === EulogiseProduct.SIDED_CARD) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_SIDED_CARD,
        {
          sidedCardId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    } else if (product === EulogiseProduct.THANK_YOU_CARD) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_THANK_YOU_CARD,
        {
          thankYouCardId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    } else if (product === EulogiseProduct.TV_WELCOME_SCREEN) {
      NavigationHelper.navigate(
        EulogisePage.EDIT_TV_WELCOME_SCREEN,
        {
          tvWelcomeScreenId: id,
        },
        query,
        ignoreUnsavedConfirmation,
      )
    }
  },

  navigate: (
    page: EulogisePage | string,
    params?: any,
    query?: { [key: string]: string },
    ignoreUnsavedConfirmation: boolean = false,
    showUnsavedChangesModalFn?: () => void,
    showUnsavedPhotoLibraryModalFn?: () => void,
  ) => {
    const proceedToPage = () => {
      if (UtilHelper.hasWindow()) {
        NavigationHelper.hasAddedUnsavedListener = false
        NavigationHelper.hasProductBeenChanged = false
        const url = UrlHelper.toUrl(page, params)
        const queryString = UrlHelper.queryStringify(query!)
        navigate(`${url}${queryString ? `?${queryString}` : ''}`)
      }
    }
    if (NavigationHelper.hasAddedUnsavedListener) {
      if (ignoreUnsavedConfirmation) {
        proceedToPage()
      } else if (showUnsavedChangesModalFn) {
        // show unsaved changes modal when the `addedUnsavedListener` is attached
        showUnsavedChangesModalFn()
      } else if (showUnsavedPhotoLibraryModalFn) {
        showUnsavedPhotoLibraryModalFn()
      } else {
        proceedToPage()
      }
    } else {
      proceedToPage()
    }
  },

  confirmUnsavedChanges: (event: any) => {
    // Cancel the event as stated by the standard.
    event.preventDefault()

    // Chrome requires returnValue to be set.
    return (event.returnValue = 'Are you sure you want to exit?')
  },

  addUnsavedListener: () => {
    console.log('addUnsavedListener')
    NavigationHelper.hasProductBeenChanged = true
    if (!NavigationHelper.hasAddedUnsavedListener) {
      // @ts-ignore
      window.addEventListener(
        'beforeunload',
        NavigationHelper.confirmUnsavedChanges,
      )
    }
    NavigationHelper.hasAddedUnsavedListener = true
  },

  removeUnsavedListener: () => {
    console.log('removeUnsavedListener')
    NavigationHelper.hasProductBeenChanged = false
    if (NavigationHelper.hasAddedUnsavedListener) {
      // @ts-ignore
      window.removeEventListener(
        'beforeunload',
        NavigationHelper.confirmUnsavedChanges,
      )
    }
    NavigationHelper.hasAddedUnsavedListener = false
  },
}
