import {
  IModalAction,
  IModalState,
  ModalActionTypes,
  ModalId,
} from '@eulogise/core'

const initialState: IModalState = {
  openModalIds: [],
}

export const ModalReducer = (
  state: IModalState = initialState,
  action: IModalAction,
) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      const modalId: ModalId = action.payload?.id!
      const isExists: boolean = !!state.openModalIds?.find(
        (id: ModalId) => id === modalId,
      )
      if (isExists) {
        return state
      }
      return {
        ...state,
        openModalIds: state.openModalIds?.concat(modalId),
        options: action.payload?.options,
      }
    }
    case ModalActionTypes.HIDE_MODAL: {
      const modalId: ModalId = action.payload?.id!
      return {
        ...state,
        openModalIds: state.openModalIds?.filter(
          (id: ModalId) => id !== modalId,
        ),
      }
    }
    default:
      return state
  }
}

export const ModalInitialState = initialState
